import { sanitizeControlFields } from 'modules/form/util/sanitize-control-fields'
import * as _ from 'modules/util'
import * as R from 'ramda'
import { useEffect, useId } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

const Form = ({
  beforeSubmit,
  children,
  defaultValues,
  disabled,
  id: idIn,
  ignoredFields = [],
  labelType,
  onChange: onChangeIn,
  onError = () => {},
  onSubmit: onSubmitIn = () => {},
  validateOn = 'submit',
  ...props
}) => {
  const randomId = useId()
  const id = idIn || randomId

  const methods = useForm({
    defaultValues,
    disabled,
    mode: 'on' + _.capitalizeFirstLetter(validateOn),
  })

  const onChangeEffect = _.useEffectEvent(onChangeIn)
  const onChange = onChangeIn ? onChangeEffect : undefined

  useEffect(() => {
    if (!onChange) return
    const subscription = methods.watch(() => {
      const fieldNames = Object.keys(methods.control._fields)
      onChange(R.pick(fieldNames, methods.getValues()))
    })
    return () => subscription.unsubscribe()
  }, [onChange, methods])

  const onSubmit = (dataIn, event) => {
    const registeredFields = sanitizeControlFields(methods.control._fields)
    const omitUnregisteredFields = _.intersectDeepRight(registeredFields)
    const data = _.thru(dataIn, omitUnregisteredFields, _.omitDeep(ignoredFields))

    return onSubmitIn?.mutateAsync
      ? onSubmitIn.mutateAsync({ body: data }).catch(() => {})
      : onSubmitIn(data, event)
  }

  const performSubmit = _.actions(beforeSubmit, methods.handleSubmit(onSubmit, onError))

  return (
    <FormProvider id={id} labelType={labelType} performSubmit={performSubmit} {...methods}>
      <form autoComplete="off" id={id} noValidate onSubmit={performSubmit} {...props}>
        {children}
      </form>
    </FormProvider>
  )
}

export default Form
